import React from 'react';
import './homepage.css';
import './Contact.css';

const Register = () => {
    return (
        <div className="container contact-section">
            <div className="section-title">Register</div>
            <div className="contact-form"> {/* Using contact-form class */}
                <form action="/register" method="post">
                    <label>Email</label>
                    <input type="email" name="email" placeholder="Email" required />
                    <label>Password</label>
                    <input type="password" name="password" placeholder="Password" required />
                    <label>Confirm Password</label>
                    <input type="password" name="confirmPassword" placeholder="Confirm Password" required />
                    <button type="submit">Register</button>
                </form>
            </div>
        </div>
    );
    
    
    
};

export default Register;

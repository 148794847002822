import React from 'react';
import './homepage.css';
import './Contact.css';

const Login = () => {
    return (
        <div className="container contact-section">
            <div className="section-title">Login</div>
            <div className="contact-form"> {/* Using contact-form class */}
                <form action="/login" method="post">
                    <label>Email</label>
                    <input type="email" name="email" placeholder="Email" required />
                    <label>Password</label>
                    <input type="password" name="password" placeholder="Password" required />
                    <button type="submit">Log In</button>
                </form>
            </div>
        </div>
    );
    
    
};

export default Login;

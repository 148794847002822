import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-input-2/lib/style.css';
import './Contact.css';
import PhoneInput from 'react-phone-input-2';
import './homepage.css';

function ContactPage() {
    const [state, handleSubmit] = useForm("moqodwap");
    const [phone, setPhone] = useState('');
    const [properties, setProperties] = useState('');  // New state variable for number of properties

    if (state.succeeded) {
        return (
            <div className="container mt-5 contact-section">
                <div className="thank-you-message">
                    <h2 className="section-title">Thank You!</h2>
                    <p>Thanks for contacting us! We'll get back to you soon.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="container mt-5 contact-section">
            <div className="contact-form">
                <h2 className="text-center section-title">Get in Touch!</h2>
                <p className="text-center">Interested in our bot? Don't hesitate to contact us.</p>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="name">Your Name</label>
                    <input type="text" className="form-control" name="name" placeholder="Your Name" required />
                    <ValidationError prefix="Name" field="name" errors={state.errors} />

                    <label htmlFor="email">Your Email</label>
                    <input type="email" className="form-control" name="email" placeholder="Your Email" required />
                    <ValidationError prefix="Email" field="email" errors={state.errors} />

                    <label htmlFor="phone">Phone Number</label>
                    <PhoneInput 
    country={'us'}
    value={phone}
    onChange={setPhone}
    placeholder="Enter phone number"
    inputStyle={{
        width: '100%',
        padding: '14px 16px',
        marginBottom: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        fontSize: '1em',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
    }}
    containerStyle={{
        width: '100%',
    }}
/>

                    <input type="hidden" name="phone" value={phone} />

                    <label htmlFor="properties">Number of Properties</label>
                    <input 
                        type="number" 
                        className="form-control" 
                        name="properties" 
                        value={properties} 
                        onChange={e => setProperties(e.target.value)} 
                        placeholder="Number of Properties" 
                        min="0" 
                        required 
                    />
                    <ValidationError prefix="Properties" field="properties" errors={state.errors} />

                    <label htmlFor="message">Your Message</label>
                    <textarea 
                        className="form-control" 
                        name="message" 
                        placeholder="If you are interested, fill in your contact information and press 'Send Message'."
                        required
                    ></textarea>
                    <ValidationError prefix="Message" field="message" errors={state.errors} />

                    <div className="text-center mt-3">
                        <button type="submit" className="proceed-payment-btn" disabled={state.submitting}>
                            Send Message
                        </button>
                    </div>
                </form>
            </div>

            <div className="social-links mt-4 text-center">
                {/* Add your social media links here using Bootstrap's icon library or your own icons */}
            </div>
        </div>
    );
}

export default ContactPage;

import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar">
            <Link to="/" className="navbar-brand">StayAssist</Link>
            <BurgerMenu />

            <div className="navbar-items">
                <a href="#about" className="navbar-item">About</a>
                <a href="#features" className="navbar-item">Features</a>
                <a href="#pricing" className="navbar-item">Pricing</a>
                <Link to="/contact" className="navbar-item active">Contact</Link>
            </div>
        </nav>
    );
};

const BurgerMenu = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div className="burger-icon" onClick={() => setIsOpen(!isOpen)}>
                &#9776;
            </div>
            <div className={`menu ${isOpen ? "open" : ""}`}>
                <button className="close-button" onClick={closeMenu}>×</button>
                <a href="#about" className="navbar-item" onClick={closeMenu}>About</a>
                <a href="#features" className="navbar-item" onClick={closeMenu}>Features</a>
                <a href="#pricing" className="navbar-item" onClick={closeMenu}>Pricing</a>
                <Link to="/contact" className="navbar-item" onClick={closeMenu}>Contact</Link>
                <Link to="/login" className="navbar-item" onClick={closeMenu}>Login</Link>
                <Link to="/register" className="navbar-item" onClick={closeMenu}>Register</Link>
            </div>
        </>
    );
};

export default Navbar;
